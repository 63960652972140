import React from "react";
import { ShortListTable } from "./ShortListTable";

export const ShortL = (props) => {
     
    return (
        <>
            <div className={`container-fluid`}>
                <ShortListTable {...props}/>
            </div>
        </>
    );
};
