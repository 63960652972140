/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

export const Loader = () => {
    return (
        <div className="container">
            <div className="card card-custom card-sticky gutter-b">
                <div className="card-body p-8 d-flex justify-content-center font-weight-bold font-size-h5">
                    Loading...
                    <div className="spinner spinner-primary ml-5"></div>
                </div>
            </div>
        </div>
    );
};
