import React from 'react';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment-timezone";

export const MockShortListColumn = (language,themeData) => [
  
    {
        Header:language.CCMODE_SHORTLISTPAGE_SHORTLISTID,
        accessor:'ConsumerId',
        className:"align-middle text-center",
        show: false
    },
    
    {
        Header:language.CCMODE_SHORTLISTPAGE_SHORTLISTNAME,
        accessor:'ListName',
        className:"align-middle text-center",
    },
    {
        Header:language.CCMODE_THEME3_CONSUMERNAME,
        accessor:'ConsumerName',
        className:"align-middle text-center",
    },
    {
        Header:language.CCMODE_THEME3_EMAIL,
        accessor:'EMail',
        className:"align-middle text-center",
    },
    {
        Header:language.CCMODE_SHORTLISTPAGE_CONTACT_NUMBER,
        accessor:'ContactNo',
        className:"align-middle text-center",
    },
   
    {
        Header:language.CCMODE_SHORTLISTPAGE_NOOFSTONES,
        accessor:'ShortListedStones',
        className:"text-center align-middle",
    },
    {
        Header:language.CCMODE_SHORTLISTPAGE_TOTALCARAT,
        accessor:'TotalCarats',
        className:"text-center align-middle",
    },
    {
        Header:language.CCMODE_SHORTLISTPAGE_NOTES,
        minwidth: 100,
        accessor:'Notes',
        className:"align-middle text-center",
        Cell: (row) => {
            return (
                <>
                    <OverlayTrigger
                        placement="left"
                        overlay={
                            <Tooltip id="tooltip">{row.value}</Tooltip>
                        }
                    >
                        <span
                            className="d-inline-block text-truncate text-dark"
                            style={{ maxWidth: "120px" }}
                        >
                            {row.value}
                        </span>
                    </OverlayTrigger>
                </>
            );
        },
    },
    {
        Header:language.CCMODE_SHORTLISTPAGE_CREATEDDATE,
        // accessor:'CreatedAt',
        accessor: (d) => {
            return (
                <>
                {/* { console.log("d.CreatedAt",d.CreatedAt)}
                { console.log("themeData",themeData.timeZone)} */}
                    {  d.CreatedAt ? moment.tz(d.CreatedAt, themeData.timeZone ? themeData.timeZone : "Etc/GMT").format("YYYY-MM-DD HH:mm:ss") : "-"}
                </>
            );
        },
        className:"text-center align-middle",
    },
]