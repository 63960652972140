import {
  SET_CC_MODE_SETTING,
  UPDATE_SHORTLIST_STONE_COUNT,
} from "./common.actions";

let initState = {
  shortlistStoneCount: 0,
};

export const ShortlistStoneReducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_SHORTLIST_STONE_COUNT: {
      return {
        ...state,
        shortlistStoneCount: action.payload?.shortListedStoneCount,
      };
    }
    default:
      return state;
  }
};

let ccModeSettingInitState = {
  hideCertificateNumber: 0,
  hideEstimatedShippingDays: 0,
  hidePrice: 0,
  displayName: null,
  displaybuybtn:0
};

export const CcModeSettingReducer = (
  state = ccModeSettingInitState,
  action
) => {
  switch (action.type) {
    case SET_CC_MODE_SETTING: {
      return {
        ...state,
        hideCertificateNumber: action.payload?.hideCertificateNumber,
        hideEstimatedShippingDays: action.payload?.hideEstimatedShippingDays,
        hidePrice: action.payload?.hidePrice,
        displayName: action.payload?.displayName,
        displaybuybtn: action.payload?.displaybuybtn
      };
    }
    default:
      return state;
  }
};
