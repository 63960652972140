import React from "react";
import {  Switch,Redirect, Route } from 'react-router-dom';
import Login from "./Login";
import WahChanLogin from "./WahChanLogin";


const HomeRoutes = () =>{
  return(
    <>
      <Switch>
        {process.env.REACT_APP_PROJECT_PATH ==="wahchanjewellery.com"
          ? <Route exact path="/" component={WahChanLogin} />
          : <Route exact path="/" component={Login} />
        }
        <Redirect from="/auth" exact={true} to="/" />
        <Redirect to="/" />
      </Switch>
    </>
  )
}
export default HomeRoutes;