export const UPDATE_SHORTLIST_STONE_COUNT = "UPDATE_SHORTLIST_STONE_COUNT";
export const SET_CC_MODE_SETTING = "SET_CC_MODE_SETTING";

export const updateShortlistStoneCount = ({ shortListedStoneCount }) => {
  return {
    type: UPDATE_SHORTLIST_STONE_COUNT,
    payload: {
      shortListedStoneCount,
    },
  };
};

export const setCcModeSetting = ({
  hideCertificateNumber,
  hideEstimatedShippingDays,
  hidePrice,
  displayName,
  displaybuybtn
}) => {
  return {
    type: SET_CC_MODE_SETTING,
    payload: {
      hideCertificateNumber,
      hideEstimatedShippingDays,
      hidePrice,
      displayName,
      displaybuybtn
    },
  };
};
