import React from "react";
import {
    Modal,
    // Button,
    // Form
} from "react-bootstrap";
// import axios from "axios";
// import {IUD_SHORTLIST, NODE_API_URL} from "./../../../env_config"
// import { GetDiamondDB ,StoreDiamondDB} from "../../_helpers/IndexedDB";
// import { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { toAbsoluteUrl } from "../../_helpers";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
const perfectScrollbarOptions = {
    wheelSpeed: 1,
    wheelPropagation: false,
};
export const ConfirmStatusPopUpNew = (props) => {
    // console.log(props)
    const {
        hideCertificateNumber,
        hidePrice,
      } = useSelector((state) => {
        return state.CcModeSettingReducer;
      });    

    function numberWithCommas(x) {
        return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    function Currency(){
        if (localStorage.getItem("user_currency") === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (localStorage.getItem("user_currency") === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "SGD") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "THB") {
            const  currencyIcon = "฿"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "MYR") {
            const  currencyIcon = "MYR"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "BND") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "DKK") {
            const  currencyIcon = "DKK"
            return currencyIcon
        }else if (localStorage.getItem("user_currency") === "SEK") {
            const  currencyIcon = "SEK"
            return currencyIcon
        }
    }
    // const history = useHistory()
    return (
        <>

            <Modal
                show={props.show}
                onHide={props.onClose}
                backdrop="static"
                keyboard={false}
                className={"natTab"}
                // size="lg"
            >
                {/* <Modal.Header closeButton>
                    <Modal.Title>Diamond Confirm Status</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center mb-10"> <h3 className="text-dark font-weight-bold">Diamonds Confirmed</h3></div>
                            <PerfectScrollbar
                                options={perfectScrollbarOptions}
                                className="scroll border-bottom"
                                style={{ maxHeight: "322px", position: "relative" }}
                            >
                                {props.diamondstatus && props.diamondstatus.map((data,index) => (
                                      <>
                                           <div className="d-flex w-100 mb-4 pb-4 px-4 justify-content-between">
                                               <div className="d-flex">
                                                   <div className="text-center p-0">
                                                       {data.AWS_Image &&  data.AWS_Image !=="null" ? (
                                                           <img
                                                               className="img-fluid mr-2"
                                                               alt={data.C_Shape}
                                                               src={data.AWS_Image}
                                                               onError={e => {
                                                                   e.currentTarget.src = toAbsoluteUrl(
                                                                       `/media/shape/${data.C_Shape && data.C_Shape.toLowerCase()}-no.png`
                                                                   )
                                                               }}
                                                               width="115px"
                                                           />
                                                       ) : (
                                                           <img
                                                               className="img-fluid mr-2"
                                                               alt={data.C_Shape}
                                                               src={toAbsoluteUrl(
                                                                   `/media/shape/${data.C_Shape && data.C_Shape.toLowerCase()}-no.png`
                                                               )}
                                                               onError={e => {
                                                                   e.currentTarget.src = toAbsoluteUrl(
                                                                       `/media/shape/noimage.jpg`
                                                                   )
                                                               }}
                                                               width="115px"
                                                               style={{ backgroundColor: "#f4f4f4" }}
                                                           />
                                                       )}
                                                   </div>
                                                   <div className="pl-2 p-0 text-left">
                                                       <div className={`mb-1 ${data.diamond_type === "N" || data.diamond_type === "W"  ? "natTab" : "labTab"}`}>
                                                           <span className={`${ data.diamond_type === "N" || data.diamond_type === "W" ? data.C_Color === "fancy" ? "F" : "W" : data.C_Color === "fancy" ? "LF" : "L"  }_diamond mr-3 small`}>
                                                               {data.diamond_type === "N" || data.diamond_type === "W" ? data.C_Color === "fancy"  ? "NF" : "N" : data.C_Color === "fancy" ? "LF" : "L"}
                                                           </span>
                                                           <span className={`text-primary font-weight-bold small ml-1`}>
                                                               {data.diamond_type === "N"|| data.diamond_type === "W" ? "N" : "L"}-{data.ProductId}
                                                           </span>
                                                           {
                                                               hideCertificateNumber ?
                                                               <span className="text-primary font-weight-bold mx-2 small">{data.Lab}</span> :
                                                               <span className="text-primary font-weight-bold mx-2 small">{data.Lab}-{data.Certi_No}</span>
                                                           }                                                            
                                                       </div>
                                                       <div className="text-dark font-size-h6 mb-2 font-weight-bold d-block">
                                                           {data.C_Shape}{" "}
                                                           {parseFloat(data.C_Weight).toFixed(2)}{" "}
                                                           {data.C_Color === "fancy" ? (
                                                               <>
                                                                   {data.f_color}{" "}
                                                               </>
                                                           ) : (
                                                               <>{data.C_Color}{" "}</>
                                                           )}
                                                           {data.C_Clarity}{" "}{data.C_Cut}{" "}{data.C_Polish}{" "}{data.C_Symmetry}{" "}
                                                           {data.C_Fluorescence}
                                                       </div>
                                                       <div >
                                                           {
                                                               !hidePrice && (                                                            
                                                                   <div className="d-flex">
                                                                       <div className="mr-4">
                                                                           <p className="text-muted font-weight-bold small mb-0 text-uppercase">{localStorage.getItem("user_currency")}/CT</p>
                                                                           <p className="text-dark font-size-h6 font-weight-bold">
                                                                               <span>{Currency()}</span>
                                                                               {numberWithCommas(props.GetPricePerCarat(data))}
                                                                           </p>
                                                                       </div>
                                                                       
                                                                       <div className="">
                                                                           <p className="text-muted font-weight-bold small mb-0 text-uppercase">Total</p>
                                                                           <p className="text-dark font-size-h6 font-weight-bold">
                                                                               <span>{Currency(localStorage.getItem("user_currency"))}</span>
                                                                               {numberWithCommas(props.GetPrice(data))}
                                                                           </p>
                                                                       </div>
                                                                   </div>
                                                               )
                                                           }                                                                                                        
                                                       </div>
                                                       
                                                   </div>
                                               </div>
                                           </div>
                                      </>
                                    ))}
                                    
                                {/* <table className="table border-bottom">
                                    <thead>
                                    <tr className="bg-gray-100 sticky-top">
                                    <th className="text-center py-3">{props.language.CONFIRM_GOODS_POPUP_DIAMOND_DETAILS}</th>
                                    <th className="text-center py-3">Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {props.diamondstatus && props.diamondstatus.map((value,index) => (
                                        <tr className={`position-relative`} key={index}>
                                            <td>
                                                <div className={`row`}>
                                                    <div className="col-2 text-center p-0">
                                                        {value.AWS_Image ? (
                                                            <img className="img-fluid mr-2" alt={value.C_Shape} src={value.AWS_Image} width="40px" />
                                                        ) : (
                                                            <img className="img-fluid mr-2" alt={value.C_Shape} src={toAbsoluteUrl(`/media/shape/${value.C_Shape.toLowerCase()}-no.png`)} onError={e => {
                                                                e.currentTarget.src = toAbsoluteUrl(
                                                                    `/media/shape/noimage.jpg`
                                                                )
                                                            }} width="40px" style={{ backgroundColor:"#f4f4f4", }} />
                                                        )}
                                                    </div>
                                                    <div className="col-10 pl-2 p-0">
                                                        <div className="mb-1">
                                                            <span
                                                                className={`${
                                                                    value.diamond_type === "N" || value.diamond_type === "W"
                                                                        ? value.C_Color ? "W":"F"  
                                                                        : value.C_Color ? "L":"LF" 
                                                                }_diamond mr-3 small`}
                                                            >
                                                                {value.diamond_type === "N" || value.diamond_type === "W"
                                                                    ? value.C_Color ? "N":"NF"  
                                                                    : value.C_Color ? "L":"LF" }
                                                            </span>
                                                            <span className="small mr-2">
                                                                {value.diamond_type === "N" || value.diamond_type === "W" ? "N" : "L"}-{value.ProductId}
                                                            </span>
                                                            <span className="text-primary small">
                                                                {value.Lab}-
                                                                {value.Certi_No}
                                                            </span>
                                                        </div>

                                                        <span className="text-dark font-weight-bold d-block small">
                                                            {value.C_Shape}{" "}
                                                            {value.C_Weight && value.C_Weight.toFixed(2)}{" "}
                                                            {value.C_Color === "fancy" ? (
                                                                <>
                                                                    {value.f_intensity}{" "}
                                                                    {value.f_overtone}{" "}
                                                                    {value.f_color}{" "}
                                                                </>
                                                            ) : (
                                                                <>
                                                                    {value.C_Color}{" "}
                                                                </>
                                                            )}
                                                            {value.C_Clarity}{" "}
                                                            {value.C_Cut}{" "}
                                                            {value.C_Polish}{" "}
                                                            {value.C_Symmetry}{" "}
                                                            {value.C_Fluorescence}{" "}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className={`text-center `}>
                                                {value.status}
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table> */}
                            </PerfectScrollbar>
                        </div>
                            <div className="col-12 text-right mt-5"><Link onClick={()=>props.onClose()} className="btn ml-2 btn-primary" > Back to cart </Link></div>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button
                        variant="primary"
                        onClick={() => {
                            props.onClose()
                            history.push("/searchdiamond")
                        }    
                        }
                    >
                        Ok
                    </Button>
                </Modal.Footer> */}
            </Modal>
        </>
    );
};
