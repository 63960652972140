import React, {useMemo,useState,useEffect} from 'react'
import {useTable, useGlobalFilter,useSortBy, usePagination, useRowSelect,useExpanded } from 'react-table';

// import ConsumerList from "./ConsumerList.json"
import { MockShortListColumn } from './Columns'; 
import GlobleFilter from './GlobleFilter';
import { FaSortDown, FaSortUp, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaPlusSquare, FaMinusSquare } from "react-icons/fa";
import { Dropdown, Modal } from 'react-bootstrap';
import { FaEllipsisV } from 'react-icons/fa'
import  {ExpandedDetails} from "./ExpandedDetails"
import axios from "axios";
import {FETCH_COUNSUMER_LIST,LOGIN} from "./../../../env_config"
// import { SessionDetails } from './SessionDetails';
import { CartDetails } from './CartDetails';
import { BsArrowLeftShort } from 'react-icons/bs';
export const ShortListTable = (props) => {
    const [isauth,setAuth] = useState(false)
    const [authmessage,setAuthMessage]= useState()
    // const [consumerData, setConsumerData] = useState([]);

    // const [password,setPassword] = useState()
    const ValidatePassword = (password) => {
        const values = {
            mail:JSON.parse(userData).mail,
            password:password
        }
        // console.log(props,"values")
        axios({
            method: 'POST',
            url: LOGIN,
            headers: { 
                'Access-Control-Allow-Origin': '*', 
                'Content-Type': 'application/json'
              },
            data: values
          })
            .then(function (res) {
                // console.log(res.data,"password")
                if(res && res.data && res.data.Customer && res.data.Customer.length > 0){
                    setAuth(true)
                }
                else{
                    setAuth(false)
                    setAuthMessage("Invalid Credentials")
                }
            })
            .catch((res) => {
                // setAuthMessage("Something Went Wrong")
            });
    }
    const [CustomizeTable, setCustomizeTable] = useState(false);
    const [items, setItems] = useState([]);
    const handleClose = () => setCustomizeTable(false);
    // const { user } = useSelector((state) => state.auth);

    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)

    const renderRowSubComponent = React.useCallback(
        ({ row }) => (
            <>
            {/* <code>{JSON.stringify({ values: row.values }, null, 2)}</code> */}
                <ExpandedDetails data={row.values} language={props.language} themeData={props.themeData}/>
            </>
        ),[]
      )

      const id = useMemo(()=>localStorage.getItem("C_id"),[])
      useEffect(() => {
       
        const getShortListData = async () => {
            axios({
                method: 'GET',
                url: `${FETCH_COUNSUMER_LIST}/${user.CustomerId}`,
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
                  },
                
                })
                .then(function (res) {
                    if(res.data.message === "UnAuthorized"){
                        localStorage.clear();
                        window.location.reload(true)
                      }
                    // console.log(res) 
                    // alert('Data added successfully');  
                    let temp = res.data.filter((ele)=>{if(ele.ConsumerId==id)return ele})
                    if(temp.length?temp:temp=res.data)
                    setItems(temp);
                })
                .catch(function (res) {
                    // console.log(res)
                });
            };
            setTimeout(() => {
                getShortListData();
            })
        }, [id]);
     
    // const ConsumerList = items;
    const columns = useMemo(()=> MockShortListColumn(props.language,props.themeData),[] )
    // const columns = useMemo(() => MockShortListColumn(props.themeData), [userinfo.userinfo]);
    const data = useMemo(()=> items,[items])

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        pageCount,
        visibleColumns, 
        setPageSize,
        setGlobalFilter,
        allColumns,
        getToggleHideAllColumnsProps,
        state:{ globalFilter, pageIndex, pageSize},
    } = useTable({
        columns,
        data,
        initialState: {
            hiddenColumns: ['ConsumerId']
        },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
        (hooks) =>{
            hooks.visibleColumns.push((columns)=>{
                return[
                    {
                    Header: () => null,
                    id: 'expander',
                    Cell: ({ row }) => (
                        <span  {...row.getToggleRowExpandedProps()}>
                            {row.original.TotalCarats == "0" ? "" : row.isExpanded ? <FaMinusSquare className="text-success"  title="Collapse"/> :  <FaPlusSquare className="text-primary" title="Expand"/> }
                        </span>
                    ),
                    },
                    ...columns,
                ]
            })
        }
    )

    if(user === undefined ){
        return
    }
    return (
        <>
            {localStorage.getItem("C_id") && localStorage.getItem("C_id") !== "-1" && localStorage.getItem("C_id") !== "-2"?
                // <SessionDetails data={{ConsumerId:localStorage.getItem("C_id")}} language={props.language} themeData={props.themeData}/>
                <CartDetails ConsumerId={{ConsumerId:localStorage.getItem("C_id")}} language={props.language} />
            :<div>{isauth?
                <div>
                    <div className="card card-custom card-sticky gutter-b">
                        <div className="card-header mb-3">
                            <div className="card-title d-block">
                                <span className="card-label font-weight-bolder text-dark">{props.language.CCMODE_SHORTLISTPAGE_SHORTLISTHISTORY}</span><br/>
                                <div className="cursor-pointer small text-hover-primary"  onClick={() =>window.history.go(-1)}><BsArrowLeftShort /> Go to search results</div>
                            </div>
                            <div className="card-toolbar flex-nowrap">
                            
                                <GlobleFilter filter={globalFilter} setFilter={setGlobalFilter} />
                                <div className="search_result_dropdown ml-3">
                                    <Dropdown >
                                        <Dropdown.Toggle size="sm" variant="clear">
                                            <FaEllipsisV />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setCustomizeTable(true)}>Customize Table</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <Modal
                                        size="sm"
                                        show={CustomizeTable}
                                        onHide={() => setCustomizeTable(false)}
                                        aria-labelledby="Customize-table"
                                    >
                                        <Modal.Header className="px-5 py-3" closeButton>
                                            <Modal.Title id="Customize-table">
                                                Customize Columns
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="p-2" style={{height:"400px", overflow:"auto"}}>
                                            <div  className="p-3" >
                                                <label className="checkbox my-2 border p-2">
                                                    <input type='checkbox' {...getToggleHideAllColumnsProps()}  />
                                                    <span className="mr-2"></span>
                                                    Show All
                                                </label> 
                                                {
                                                    allColumns.map(column =>(
                                                        <div key={column.id}>
                                                            <label className="checkbox my-2 border p-2">
                                                                <input type='checkbox' {...column.getToggleHiddenProps()}  />
                                                                <span className="mr-2"></span>
                                                                {column.Header}
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </Modal.Body>
                                        <Modal.Footer className="px-5 py-3">
                                            <button type="button" className="btn btn-light-primary mr-2 text-nowrap" onClick={handleClose}>Close</button>
                                            {/* <button type="button" className="btn btn-primary mr-2 text-nowrap" onClick={handleClose}>Save Changes</button> */}
                                        </Modal.Footer>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-7 pb-0 mt-n3">
                            <div className="table-responsive w-100">
                                <table className="table table-bordered table-hover" {...getTableProps()}>
                                    <thead className="custom_datatable_head" >
                                        {headerGroups.map(headerGroup =>(
                                            <tr {...headerGroup.getHeaderGroupProps()}>
                                                { headerGroup.headers.map((column)=>(
                                                    <th className="text-center align-middle" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                        {column.render('Header')}
                                                        <span className="text-secondary">
                                                            {column.isSorted ? (column.isSortedDesc ? <FaSortDown /> : <FaSortUp />): ''}
                                                        </span>
                                                    </th>
                                                ))}
                                            </tr>
                                        ))}
                                    </thead>
                                    <tbody {...getTableBodyProps()}>
                                        {items.length !== 0 ? page.map((row,i) => {
                                            prepareRow(row)
                                            return(
                                                <React.Fragment key={i}>
                                                    <tr {...row.getRowProps()} key={row.id}>
                                                        {row.cells.map((cell,i) => {
                                                            return <td  className="text-center align-middle" {...cell.getCellProps({ className: cell.column.className})} key={i}>{cell.render('Cell')}</td>
                                                        })}
                                                    </tr>
                                                    {row.isExpanded ? (
                                                        <tr className='expanded_tr'>
                                                            <td className="p-0" colSpan={visibleColumns.length}>
                                                                {renderRowSubComponent({ row })} 
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                </React.Fragment>
                                            )
                                        }): 
                                            <tr>
                                                <td className="text-center align-middle"colSpan="100%">
                                                    No data found
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card-footer border-0">
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="table_page_length">{props.language.CCMODE_THEME3_SEARCHDIAMOND_PAGE}{' '}{pageIndex + 1 } of {pageOptions.length} {' '}</span>
                                <div>
                                    <button className="btn btn-sm btn-light mr-2" onClick={()=>gotoPage(0) } disabled={!canPreviousPage}><FaAngleDoubleLeft /></button>
                                    <button className="btn btn-sm btn-light mr-2" onClick={() => previousPage()} disabled={!canPreviousPage}><FaAngleLeft /></button>
                                    <span className="font-weight-bold text-muted">{'  '}
                                        <input
                                            className="form-control d-inline-block form-control-sm"
                                            type="number"
                                            value={pageIndex + 1}
                                            // defaultValue={pageIndex + 1}
                                            onChange={ e => { 
                                                const pageNumber=e.target.value ? Number(e.target.value) - 1 : 0
                                                gotoPage(pageNumber)
                                            }}
                                            style={{maxWidth:'70px'}}
                                        />
                                        {'  '}of{'  '} {pageOptions.length}{'  '}
                                    </span>
                                    <button className="btn btn-sm btn-light mr-2" onClick={() => nextPage()} disabled={!canNextPage}><FaAngleRight /></button>
                                    <button className="btn btn-sm btn-light" onClick={()=>gotoPage(pageCount - 1)} disabled={!canNextPage}><FaAngleDoubleRight /></button>
                                </div>
                                <span className="d-flex align-items-center">
                                <span className="text-nowrap page_size">{props.language.CCMODE_THEME3_SEARCHDIAMOND_ROWSPERPAGE}:{' '}</span>
                                    <select className="form-select ml-2 custom-select py-1" value={pageSize} onChange={e => setPageSize(Number(e.target.value))}>
                                        {
                                            [10, 25, 50].map(pageSize => (
                                                <option key={pageSize} value={pageSize}>{pageSize}</option>
                                            ))
                                        }
                                    </select>
                                </span>
                            </div>
                        </div>
                    </div>
                    {/* <pre>
                        <code>
                            {JSON.stringify(
                                {
                                    // selectedFlatRows:selectedFlatRows.map((row)=>row.original),
                                    selectedRowIds: selectedRowIds,
                                    'selectedFlatRows[].original': selectedFlatRows.map( row => row.value ),
                                }, null, 2
                            )}
                        </code>
                    </pre> */}
                </div> 
            :
                <div>
                    <Modal
                        show={true}
                        onHide={false}
                        backdrop="static"
                        keyboard={false}
                        className='natTab'
                    >
                        <Modal.Header >
                            <Modal.Title>{props.language.CCMODE_SHORTLISTPAGE_PASSWORD}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12">
                                    <label htmlFor="">{props.language.CCMODE_SHORTLISTPAGE_PASSWORD1}:</label>
                                    <div className="form-group fv-plugins-icon-container">
                                        <input
                                        placeholder={props.language.CCMODE_SHORTLISTPAGE_PASSWORD1}
                                        type="password"
                                        className={`form-control form-control-lg`}
                                        name="Password"
                                        autoComplete="off"
                                        onChange={(e) => ValidatePassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                            </div>
                        {authmessage ?<span className='text-danger'>{authmessage}</span>:""}
                        {/* <div className="row">
                                <div className="col-md-12">
                                    <div className="sum_tab text-center">
                                        <button
                                        type="submit"
                                        className={`submit btn btn-success px-10 py-3 shadow-sm my-4`}
                                        onClick={() => ValidatePassword()}
                                        >
                                        Verify Password
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                        </Modal.Body>
                        <Modal.Footer>
                            <button className="btn-light-primary btn btn-sm" onClick={() => window.history.back()}>Close</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            }
            </div>}
        </>
    )
}