/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState,useMemo } from "react";
import axios from "axios";
import { Table, OverlayTrigger, Tooltip } from "react-bootstrap";
import {ImagePopup} from "./../tables/imagePopup";
import {VideoPopup} from "./../tables/videoPopup";
import { FaAward ,FaCheckCircle, FaTimesCircle } from 'react-icons/fa'
import {FETCH_CONSUMER_SHORTLIST_DATA} from "./../../../env_config"
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import CertificateLink from "../CertificateLink";



export const ExpandedDetails = (data) => {

    const userData = useMemo(()=>localStorage.getItem("userData"),[])
    const user = JSON.parse(userData)
    const { hidePrice, hideCertificateNumber } = useSelector(
      (state) => state.CcModeSettingReducer
    );

    const [consumerData, setConsumerData] = useState([]);
    useEffect(() => {
        const finalData = `{  "customerId": ${user.CustomerId}, "ConsumerId":${data.data.ConsumerId} }`;
        const getShortListData = async () =>  {
        
            axios({
            method: 'POST',
            url: FETCH_CONSUMER_SHORTLIST_DATA,
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-Type": "application/json",
                "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
              },
                data: finalData,
            })
                .then( (res) =>{
                    if(res.data.message === "UnAuthorized"){
                        localStorage.clear();
                        window.location.reload(true)
                      }
                setConsumerData( res.data.Result)
            })
            .catch(function (res) {
                console.log(res)
            });
        };
            
            getShortListData();
           
        }, [user.CustomerId , data.data.ConsumerId]);

    // function jugaadDate(x) {

    //     const originalTimestamp = new Date(x);
    //     const offsetHours = -5; // Offset in hours
    //     const offsetMinutes = -30; // Offset in minutes
        
    //     // Apply the offset
    //     originalTimestamp.setHours(originalTimestamp.getHours() + offsetHours);
    //     originalTimestamp.setMinutes(originalTimestamp.getMinutes() + offsetMinutes);
        
    //     // Format the result as a string
    //     const formattedTimestamp = originalTimestamp.toISOString();
    
    
    //     return formattedTimestamp
    // }

    if(consumerData === undefined ){
        return
    }
    function numberWithCommas(x) {
        if(x){
            return x.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        else{
            return "0.00"
        }
    }
    function Currency(Currency){
        if (Currency === "USD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (Currency === "INR"){
            const  currencyIcon = "₹"
            return currencyIcon
        } else if (Currency === "HKD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (Currency === "AUD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (Currency === "EUR"){
            const  currencyIcon = "€"
            return currencyIcon
        } else if (Currency === "CAD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (Currency === "CNY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (Currency === "GBP"){
            const  currencyIcon = "£"
            return currencyIcon
        } else if (Currency === "NZD"){
            const  currencyIcon = "$"
            return currencyIcon
        } else if (Currency === "JPY"){
            const  currencyIcon = "¥"
            return currencyIcon
        } else if (Currency === "CHF"){
            const  currencyIcon = "₣"
            return currencyIcon
        }else if (Currency === "SGD") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (Currency === "THB") {
            const  currencyIcon = "฿"
            return currencyIcon
        }else if (Currency === "MYR") {
            const  currencyIcon = "MYR"
            return currencyIcon
        }else if (Currency === "BND") {
            const  currencyIcon = "$"
            return currencyIcon
        }else if (Currency === "DKK") {
            const  currencyIcon = "DKK"
            return currencyIcon
        }else if (Currency === "SEK") {
            const  currencyIcon = "SEK"
            return currencyIcon
        }
    }
    return (
        <>
            <div className="sh_expand">
            {/* {consumerData} */}
                <Table bordered responsive>
                    <thead>
                        <tr>
                            <th className="text-center text-nowrap">{data.language.CCMODE_SHORTLISTPAGE_DIAMONDTYPE}</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_THEME1_LIST_MEDIA}</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_SHORTLISTPAGE_STOCKNO}</th>
                            {
                                !hideCertificateNumber && (
                                    <th className="text-center text-nowrap">{data.language.CCMODE_DIAMOND_DETAILS_CERTIFICATE}</th>
                                )
                            }                            
                            <th className="text-center text-nowrap">{data.language.CCMODE_DIAMOND_DETAILS_SHAPE}</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_DIAMOND_DETAILS_COLOR}</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_DIAMOND_DETAILS_CARAT}</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_DIAMOND_DETAILS_CUT}</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_DIAMOND_DETAILS_CLARITY}</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_DIAMOND_DETAILS_POLISH}</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_DIAMOND_DETAILS_SYMMETRY}</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_DIAMOND_DETAILS_FLOUR}</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_DIAMOND_DETAILS_LAB}</th>                            
                            {
                                !hidePrice && ( 
                                    <>
                                        <th className="text-center text-nowrap">{data.language.CCMODE_SHORTLISTPAGE_CURRENCY}</th>
                                        <th className="text-center text-nowrap">$/CT</th>
                                        <th className="text-center text-nowrap">{data.language.CCMODE_SHORTLISTPAGE_PRICE}</th>        
                                    </>                                    
                                )
                            }                            
                            <th className="text-center text-nowrap">{data.language.CCMODE_THEME1_LIST_MEASUREMENT}</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_THEME1__LIST_DEPTH} %</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_THEME1__LIST_TABLE} %</th>
                            <th className="text-center text-nowrap">{data.language.CCMODE_SHORTLISTPAGE_CREATEDDATE} %</th>
                        </tr>
                    </thead>
                    {consumerData? <tbody>
                        { consumerData.map((val, index) => {
                            return (
                                <tr key={val.ProductId}>
                                    <td className="text-center">
                                        {val.WLDiamondType==="N"?("Natural"):("Lab")}
                                    </td>
                                    <td className="text-center d-flex">
                                        <span>
                                            {val.AWS_Image === "" ? (
                                                <span style={{ width: "14px" }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            ) : (
                                                <OverlayTrigger
                                                    placement="left"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Image
                                                        </Tooltip>
                                                    }
                                                >
                                                    <ImagePopup Image={val.AWS_Image} Shape={val.C_Shape} />
                                                </OverlayTrigger>
                                            )}
                                        </span>
                                        <span>
                                            {val.video === "" ? (
                                                <span style={{ width: "14px" }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            ) : (
                                                <OverlayTrigger
                                                    placement="left"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Video
                                                        </Tooltip>
                                                    }
                                                >
                                                    <VideoPopup ProductId={val.ProductId} WLDiamondType={val.diamond_type} CERTIFICATE_LINK={val.CERTIFICATE_LINK} Video={val.VIDEO}/>
                                                </OverlayTrigger>
                                            )}
                                        </span>
                                        <span>
                                            {val.Certificate_link === "" ? (
                                                <span style={{ width: "14px" }}>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            ) : (
                                                <OverlayTrigger
                                                    placement="left"
                                                    overlay={
                                                        <Tooltip id="tooltip">
                                                            Certificate
                                                        </Tooltip>
                                                    }
                                                >
                                                    <CertificateLink lab={val.Lab} certificate={val.Certi_No} useIcon className={`text-primary mx-2 text-center`} />
                                                </OverlayTrigger>
                                            )}
                                        </span>
                                    </td>
                                    <td className={`${val.WLDiamondType === "L" ? "labTab" : val.WLDiamondType === "N" ? "natTab" : ""} text-left pl-5 text-nowrap text-primary`}>
                                        {val.Status === "0" ?
                                            <OverlayTrigger
                                                placement="left"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        In Stock
                                                    </Tooltip>
                                                }
                                            >
                                                <FaCheckCircle className="text-success mr-2 font-size-h5"/>
                                            </OverlayTrigger>
                                        :
                                            
                                            <OverlayTrigger
                                                placement="left"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        Out of Stock
                                                    </Tooltip>
                                                }
                                            >
                                                <FaTimesCircle className="text-danger mr-2 font-size-h5"/>
                                            </OverlayTrigger>
                                        }
                                        {val.WLDiamondType}-{val.ProductId}
                                    </td>
                                    {
                                        !hideCertificateNumber && (
                                            <td className="text-center">{val.Certi_No}</td>
                                        )
                                    }                                    
                                    <td className="text-center">{val.C_Shape}</td>
                                    <td className="text-center">{val.C_Color}</td>
                                    <td className="text-center">{val.C_Weight.toFixed(2)}</td>
                                    <td className="text-center">{val.C_Cut}</td>
                                    <td className="text-center">{val.C_Clarity}</td>
                                    <td className="text-center">{val.C_Polish}</td>
                                    <td className="text-center">{val.C_Symmetry}</td>
                                    <td className="text-center">{val.C_Fluorescence}</td>
                                    <td className="text-center">{val.Lab}</td>                                    
                                    {
                                        !hidePrice && (
                                            <>
                                                <td className="text-center">{val.Currency}</td>
                                                <td className="text-right">
                                                    <span>{Currency(val.Currency)}</span>
                                                    {numberWithCommas(val.MarkUpRate)}
                                                </td>
                                                <td className="text-right">
                                                    <span>{Currency(val.Currency)}</span>
                                                    {numberWithCommas(val.MarkUpPrice)}
                                                </td>
                                            </>
                                        )
                                    }                                    
                                    <td className="text-center">{parseFloat(val.C_Length).toFixed(2)}x{parseFloat(val.C_Width).toFixed(2)}x{parseFloat(val.C_Depth).toFixed(2)}</td>
                                    <td className="text-center">{parseFloat(val.C_DefthP).toFixed(2)}</td>
                                    <td className="text-center">{parseFloat(val.C_TableP).toFixed(2)}</td>
                                    <td className="text-center text-nowrap"> { val.CreatedAt ? moment.tz(val.CreatedAt, data.themeData.timeZone ? data.themeData.timeZone : "Etc/GMT").format("YYYY-MM-DD HH:mm:ss") : "-"}</td>
                                </tr>
                            );
                        })}
                    </tbody> :""}
                </Table>
            </div>
        </>
    );
};
