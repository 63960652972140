import React, { useState,useEffect } from 'react';
import {  Modal, Button } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
// import {TextField} from '@mui/material/TextField';
import {Autocomplete} from '@material-ui/lab';
import {TextField} from '@material-ui/core';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import {FETCH_COUNSUMER_LIST} from "./../../../env_config"

export const PrevSesModel = () => {
  // const { user } = useSelector((state) => state.auth);

  const [user, setUser] = React.useState([]);

  React.useEffect(() => {
    const userData = localStorage.getItem("userData");
    setUser(JSON.parse(userData))
  },[])

  const [items, setItems] = useState([]);
  const [cid, setCid] = useState([]);
  const history = useHistory();

  // const [value, setValue] = React.useState();
  const [inputValue, setInputValue] = React.useState('');


    const handleSubmit = () =>{
      window.localStorage.setItem("C_id", cid)
      setTimeout(() => {
          history.push("/searchdiamond");
          setshowPrevModel(false);
          setTimeout(() => {
            window.location.reload(true)
        }, 100);
      }, 1000);
    }

    const [showPrevModel, setshowPrevModel] = useState(false);
    const handleClose = () => setshowPrevModel(false);
    const handleShow = () => {
      setshowPrevModel(true);
      axios({
        method: 'GET',
        url:`${FETCH_COUNSUMER_LIST}/${user.CustomerId}` ,
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
        },
        
        })
        .then(function (res) {
          if(res.data.message === "UnAuthorized"){
            localStorage.clear();
            window.location.reload(true)
          }
            // console.log(res.data)
            // console.log(res)
            setItems(res.data);
        })
        .catch(function (res) {
            console.log(res)
            // console.log(user.CustomerId)
        });
    }
  // console.log(cid)
  // console.log('value', cid)
    if(user === undefined ){
      return
    }

    return (
      <>
        <button className="btn btn-primary btn-lg" onClick={handleShow}>Start with Previous Session</button>
        <Modal
          show={showPrevModel}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Selet Consumer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Autocomplete
              disablePortal
              id="select-consumer"
              options={items}
              getOptionLabel={(items) => items.ListName}
              sx={{ width: 300 }}
              renderInput={(params) => <TextField {...params} label="Select Consumer" />}
              onChange={(event, newValue) => {
                setCid(newValue.ConsumerId);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
      
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button onClick={handleSubmit} variant="primary">Start</Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
  