import React from 'react';
import { FaSearch } from "react-icons/fa";

function GlobleFilter({filter, setFilter}) {
    return (
        <>
            <div className="input-group input-group-sm">
                <div className="input-group-prepend">
                    <span className="input-group-text line-height-0 py-0"><FaSearch /></span>
                </div>
                <input className="form-control input-search" value={filter || ''} onChange={(e) => setFilter(e.target.value)} />
            </div>  
        </>
    )
}

export default GlobleFilter