import React, { useState } from 'react'
import { useEffect } from 'react'
import DiamondContext from './SearchDiamondContext'
import { OpenDiamondDB ,GetDiamondDB} from '../../_helpers/IndexedDB'
const SearchDiamondProvider = ({ children }) => {
  useEffect(() => {
    // console.log('useEffect called');
    GetDiamondDB().then(response => {
      if(response){
        setData((prevState) => {
          return{
            ...prevState,
            value:response
          }
        })
      }
    })
  }, [data]);
  function updateState(){
    GetDiamondDB().then(response => {
      if(response){
        // console.log(response,"DATAUPDATE IS TRIGGERED")
        setData((prevState) => {
          return{
            ...prevState,
            value:response
          }
        })
      }
    })
  }
      const [data, setData] = useState({
        value:[],
        updateState
      })
    return (
      <DiamondContext.Provider value={data}>
        {children}
      </DiamondContext.Provider>
    )
  }
  
  export default SearchDiamondProvider