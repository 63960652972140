// export const LOGIN = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_LOGIN : '';
// export const FETCH_DIAMOND = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_FETCH_DIAMOND : '';
// export const IUD_SHORTLIST = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_IUD_SHORTLIST : '';
// export const SEARCH_COUNT = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_SEARCH_COUNT : '';
// export const SEARCH_LOG = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_SEARCH_LOG : '';
// export const IUD_CONSUMER_DETAILS = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_IUD_CONSUMER_DETAILS : '';
// export const FETCH_COUNSUMER_LIST = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_FETCH_COUNSUMER_LIST : '';
// export const FETCH_CONSUMER_SHORTLIST_DATA = process.env.NODE_ENV !== 'production' ? process.env.REACT_APP_FETCH_CONSUMER_SHORTLIST_DATA : '';

// import dotenv from 'dotenv'

// if(process.env.NODE_ENV !== 'production'){
    // dotenv.config()
    // require('dotenv').config();
// }


if(process.env.REACT_APP_ENV === 'qc'){

    var apilink = {
        LOGIN : process.env.REACT_APP_LOGIN ,
        FETCH_DIAMOND : process.env.REACT_APP_FETCH_DIAMOND,
        IUD_SHORTLIST : process.env.REACT_APP_IUD_SHORTLIST,
        SEARCH_COUNT : process.env.REACT_APP_SEARCH_COUNT,
        SEARCH_LOG : process.env.REACT_APP_SEARCH_LOG,
        IUD_CONSUMER_DETAILS : process.env.REACT_APP_IUD_CONSUMER_DETAILS,
        FETCH_COUNSUMER_LIST : process.env.REACT_APP_FETCH_COUNSUMER_LIST,
        FETCH_CONSUMER_SHORTLIST_DATA : process.env.REACT_APP_FETCH_CONSUMER_SHORTLIST_DATA,
        FETCH_CURRENCY : process.env.REACT_APP_FETCH_CURRENCY,
        DETAILPAGE_LOG : process.env.REACT_APP_IUD_DETAILPAGE_LOG,
        NODE_API_URL:process.env.REACT_APP_FETCH_COUNSUMER_LIST,
        LOGO_BASE_URL: process.env.REACT_APP_LOGO_BASE_URL,
    };

}else{

    var apilink = {
        LOGIN : process.env.REACT_APP_LOGIN_PROD ,
        FETCH_DIAMOND : process.env.REACT_APP_FETCH_DIAMOND_PROD,
        IUD_SHORTLIST : process.env.REACT_APP_IUD_SHORTLIST_PROD,
        SEARCH_COUNT : process.env.REACT_APP_SEARCH_COUNT_PROD,
        SEARCH_LOG : process.env.REACT_APP_SEARCH_LOG_PROD,
        IUD_CONSUMER_DETAILS : process.env.REACT_APP_IUD_CONSUMER_DETAILS_PROD,
        FETCH_COUNSUMER_LIST : process.env.REACT_APP_FETCH_COUNSUMER_LIST_PROD,
        FETCH_CONSUMER_SHORTLIST_DATA : process.env.REACT_APP_FETCH_CONSUMER_SHORTLIST_DATA_PROD,
        FETCH_CURRENCY : process.env.REACT_APP_FETCH_CURRENCY_PROD,
        DETAILPAGE_LOG : process.env.REACT_APP_IUD_DETAILPAGE_LOG_PROD,
        NODE_API_URL:process.env.REACT_APP_FETCH_COUNSUMER_LIST_PROD,
        LOGO_BASE_URL: process.env.REACT_APP_LOGO_BASE_URL_PROD,
    };
}


//mysql connection database for beta server

//exporting apilink

module.exports = apilink;