import React, {useMemo, useState} from "react";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { TiThMenu } from "react-icons/ti";

export function HeaderMobile() {
  const uiService = useHtmlClassService();
  const [FullScreen,setFullScreen] = useState(false)  
  // const { user } = useSelector((state) => state.auth);
  const [user, setUser] = React.useState([]);
  const [imageBroken, setImageBroken] = React.useState(false);

  React.useEffect(() => {
    const userData = localStorage.getItem("userData");
    setUser(JSON.parse(userData))
  },[])

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
          objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile")
    };
  }, [uiService]);
  
  if(user === undefined ){
      return
  }
  
  function fullscreen() {
    const elem = document.documentElement;

    if (!document.fullscreenElement && !document.webkitFullscreenElement) {
        // Enter fullscreen
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen(); // Safari
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    } else {
        // Exit fullscreen
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen(); // Safari
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }
}

document.addEventListener('fullscreenchange', exitHandler);
document.addEventListener('webkitfullscreenchange', exitHandler);
document.addEventListener('mozfullscreenchange', exitHandler);
document.addEventListener('MSFullscreenChange', exitHandler);

function exitHandler() {
    if (!document.fullscreenElement && !document.webkitFullscreenElement) {
        setFullScreen(false);
    } else {
        setFullScreen(true);
    }
}

  return (
      <>
        {/*begin::Header Mobile*/}
        <div
            id="kt_header_mobile"
            className={`header-mobile align-items-center c_header natTab shadow-sm ${layoutProps.headerMobileCssClasses}`}
            {...layoutProps.headerMobileAttributes}
        >
          {/*begin::Logo*/} 
          {user.Logo ?
            imageBroken ? (
                <h2 className="text-uppercase text-dark"> {user.CompanyName} </h2>
              ) : (
                <img
                alt={user.CompanyName}
                src={`https://alpha.diamondsupload.com/assets/white_label/${user.Logo}`}
                  height="50px"
                  onError={() => setImageBroken(true)}
                />)
            :<h2 className="text-uppercase text-dark"> {user.CompanyName} </h2>}
          {/*end::Logo*/}

          {/*begin::Toolbar*/}
          <div className="d-flex align-items-center">
            {layoutProps.asideDisplay && (
                <>
                  {/*begin::Aside Mobile Toggle*/}
                  <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                    <span/>
                  </button>
                  {/*end::Aside Mobile Toggle*/}
                </>
            )}
            <div className="d-md-none">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="quick-user-tooltip">{!FullScreen?"Enter Full Screen":"Exit Full Screen"}</Tooltip>}
              >
                <div className="topbar-item " onClick={() => fullscreen()}>
                  <div
                    className="btn btn-icon w-auto btn-clear d-flex align-items-center btn-sm mr-3"
                  >
                    <>
                        {!FullScreen?<i className="fas fa-expand text-primary"></i>:<i className="fas fa-solid fa-compress text-primary"></i>}
                    </>
                  </div>
                </div>
              </OverlayTrigger>
            </div>

            {/*begin::Topbar Mobile Toggle*/}
            <button
                className="btn btn-hover-text-primary p-0 ml-2"
                id="kt_header_mobile_topbar_toggle"
            >
              <span className="svg-icon svg-icon-xl">
                <TiThMenu className="text-primary" />
                {/* <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} /> */}
              </span>
            </button>
            {/*end::Topbar Mobile Toggle*/}
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header Mobile*/}
      </>
  );
}
