import React from "react";
import { PrevSesModel } from "./PrevSesModel"
import { NewSesModel } from "./NewSesModel"
import { WithoutSession } from "./WithoutSession"


export const SelectSession = () => {
    // React.useEffect(() => window.localStorage.setItem("C_id", ''), []);
    return (
        <>
            <div className={`container`}>
                <div className="card card-custom card-sticky gutter-b">
                    <div className="card-header mb-3">
                        <div className="card-title m-0">
                            <span className="card-label text-dark">
                                Select Session
                            </span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 text-center my-5">
                                <PrevSesModel />
                            </div>
                            <div className="col-12 text-center my-5">
                                <NewSesModel />
                            </div>
                            {/* <div className="col-12 text-center my-5">
                                <WithoutSession />
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
