import React, { Suspense } from "react";
import { Redirect, Switch, } from "react-router-dom";
// import { useSelector } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { ShortListPage } from "./pages/ShortListPage";
import "../assets/css/BackendStyle.css"
import IdleTimerContainer from "./IdleTimerContainer";
import { Loader } from "../_metronic/_partials/Loader";
// import * as auth from "../Auth";
const SearchResultTheme1 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme1`).then(module => ({ default: module.SearchResultTheme1 })));
const SearchDiamondTheme1 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme1`).then(module => ({ default: module.SearchDiamondTheme1 })));
const GridCardTheme1 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme1`).then(module => ({ default: module.GridCardTheme1 })));
const ProductDetailMainTheme1 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme1`).then(module => ({ default: module.ProductDetailMainTheme1 })));

const SearchResultTheme2 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme2`).then(module => ({ default: module.SearchResultTheme2 })));
const SearchDiamondTheme2 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme2`).then(module => ({ default: module.SearchDiamondTheme2 })));
const GridCardTheme2 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme2`).then(module => ({ default: module.GridCardTheme2 })));
const ProductDetailMainTheme2 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme2`).then(module => ({ default: module.ProductDetailMainTheme2 })));

const SearchResultTheme3 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme3`).then(module => ({ default: module.SearchResultTheme3 })));
const SearchDiamondTheme3 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme3`).then(module => ({ default: module.SearchDiamondTheme3 })));
const GridCardTheme3 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme3`).then(module => ({ default: module.GridCardTheme3 })));
const ProductDetailMainTheme3 = React.lazy(() => import(`../_metronic/${process.env.REACT_APP_PROJECT_PATH}/theme3`).then(module => ({ default: module.ProductDetailMainTheme3 })));

export default function BasePage({theme,user,ruleData,naturalData,naturalFancyData,labData,labFancyData,language,themeData}) {
 
  // const { user } = useSelector((state) => state.auth);
  // const [theme , setTheme] = useState('theme1');
  // const [user, setUser] = React.useState([]);
  // const [id, setID] = React.useState();

  // console.log("theme",theme)
  // console.log("ruleData",ruleData)
  // console.log(language,"language")
  return (
    <>
      <IdleTimerContainer /> 
        <Suspense fallback={<LayoutSplashScreen />}>
          <Switch>
            <Redirect exact from="/" to="/searchdiamond" />  
            {/* new */}
            {theme === 'theme1' ? 
                <>
                  {/* theme1  */}
                  <ContentRoute path="/search-result" component={() => <SearchResultTheme1 language={language}/>} />
                  <ContentRoute path="/grid" component={() => <GridCardTheme1 language={language}/>} />
                  <ContentRoute path="/details" component={() => <ProductDetailMainTheme1 language={language}/>} />
                  <ContentRoute path="/shortlist" component={() => <ShortListPage language={language} themeData={themeData}/>} />
                  <ContentRoute path="/searchdiamond" component={()=><SearchDiamondTheme1 ruleData={ruleData} user={user} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} language={language}/>}/>
                </>
            : theme === 'theme2' ? 
              <>
                {/* theme2  */}
                <ContentRoute path="/search-result" component={SearchResultTheme2} />
                <ContentRoute path="/grid" component={GridCardTheme2} />
                <ContentRoute path="/details" component={() => <ProductDetailMainTheme2 language={language}/>} />
                <ContentRoute path="/shortlist" component={() => <ShortListPage language={language} themeData={themeData}/>} />
                <ContentRoute path="/searchdiamond" component={()=><SearchDiamondTheme2 ruleData={ruleData} user={user} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} language={language} />} />
              </> 
            : theme === 'theme3' ?
              <>
                {/* theme3  */}
                <ContentRoute path="/search-result" component={SearchResultTheme3} />
                <ContentRoute path="/grid" component={GridCardTheme3} />
                <ContentRoute path="/details" component={() => <ProductDetailMainTheme3 language={language}/>} />
                <ContentRoute path="/shortlist" component={() => <ShortListPage language={language} themeData={themeData}/>} />
                <ContentRoute path="/searchdiamond" component={()=><SearchDiamondTheme3 ruleData={ruleData} user={user} naturalData={naturalData} naturalFancyData={naturalFancyData} labData={labData} labFancyData={labFancyData} language={language} />} />
              </> 
            :
                <>
                  <ContentRoute path="/searchdiamond" component={Loader} />
                </>
              }
            <Redirect to="error/error-v1" />
          </Switch>
        </Suspense>
    </>

  );
}
