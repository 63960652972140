import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { CircularProgress } from '@material-ui/core';
export default function VideoIframe({ showVideo, handleClose, src }) {
  const [frameloaded, setFrameLoaded] = useState(false)
  const FrameFunction = () => {
    // console.log("DONE")
    setFrameLoaded(true)
    // var iframe = document.getElementById('iframedata');
    // console.log(iframe.contentWindow,"iframeDoc")
    // console.log($("#iframedata"),"FRAMEID")
  }
  return (
    <div>
      <Modal
        show={showVideo}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        dialogClassName="custom-modal"
        centered
    >
        
        <Modal.Body >
          <div className="video-container">
            {frameloaded ? "" : 
              <div className="loader-container">
                  <CircularProgress />
                  <h6>Please Wait...</h6>
              </div>
            }
            <iframe
              id="iframedata"
              title="Video"
              src={src}
              style={{
                display: frameloaded ? "block" : "none",
                border: "none", // Remove any border
              }}
              onLoad={FrameFunction}
              onError={() => console.log("ERRORRRRRRR")}
              allowFullScreen
              frameBorder="0"
              className="video-iframe"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}