import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {
  CcModeSettingReducer,
  ShortlistStoneReducer,
} from "./commonRedux/common.reducer";
import CommonSaga from "./commonRedux/common.saga";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  ShortlistStoneReducer,
  CcModeSettingReducer,
});

export function* rootSaga() {
  yield all([auth.saga(), CommonSaga]);
}
