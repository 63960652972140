import { put, takeLatest } from "redux-saga/effects";
import {
  SET_CC_MODE_SETTING,
  UPDATE_SHORTLIST_STONE_COUNT,
  updateShortlistStoneCount,
} from "./common.actions";

function* updateShortlistStoneCountSaga(action) {
  yield put(updateShortlistStoneCount({ action }));
}

function* setCcModeSettingSaga(action) {
  yield put(setCcModeSettingSaga(action));
}

export default function* CommonSaga() {
  yield takeLatest(UPDATE_SHORTLIST_STONE_COUNT, updateShortlistStoneCountSaga);
  yield takeLatest(SET_CC_MODE_SETTING, setCcModeSettingSaga);
}
