/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useMemo ,useState} from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import { useSelector, shallowEqual } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import MultiCurrency from "./MultiCurrency";
import MultiLingual from "./MultiLingual";
import { Link } from "react-router-dom";
import axios from "axios";
import { FETCH_COUNSUMER_LIST } from "../../../../env_config";
import { useDispatch, useSelector } from "react-redux";
import { updateShortlistStoneCount } from "../../../../redux/commonRedux/common.actions";
import { FaShoppingCart } from "react-icons/fa";

export function QuickUserToggler(props) {   
  // const { user } = useSelector((state) => state.auth, shallowEqual);
  const userData = useMemo(()=>localStorage.getItem("userData"),[])
  const dispatch = useDispatch();
  const user = JSON.parse(userData)
  const [FullScreen,setFullScreen] = useState(false)  
  const [shortListedStone, setShortListedStone] = useState()  
  const uiService = useHtmlClassService();   
  const sessionId = localStorage.getItem("C_id");  

  const shortlistedStoneCount = useSelector((state) => {
    return state.ShortlistStoneReducer?.shortlistStoneCount;
  });

  useEffect(() => {
    const config = {
      method: "GET",
      url: `${FETCH_COUNSUMER_LIST}/${user.CustomerId}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
      },
    };
    axios(config).then((res) => {
      if(res.data.message === "UnAuthorized"){
        localStorage.clear();
        window.location.reload(true)
      }
      let totalShortlistedStone = 0;
      let shortlistedDatas = res.data;
      if (sessionId >= 0) {
        shortlistedDatas = res.data.filter(
          (elem) => elem.ConsumerId == sessionId
        );
      }

      for (const shortlistedData of shortlistedDatas) {
        totalShortlistedStone =
          shortlistedData.ShortListedStones + totalShortlistedStone;
      }
      dispatch(
        updateShortlistStoneCount({ shortListedStoneCount: totalShortlistedStone })
      );

      setShortListedStone(totalShortlistedStone);
    });
  }, []);

  useEffect(() => {
    setShortListedStone(shortlistedStoneCount);
  }, [shortlistedStoneCount]);
  
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  function fullscreen() {
    const elem = document.documentElement;

    if (!document.fullscreenElement && !document.webkitFullscreenElement) {
        // Enter fullscreen
        if (elem.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen(); // Safari
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        }
    } else {
        // Exit fullscreen
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen(); // Safari
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }
}

document.addEventListener('fullscreenchange', exitHandler);
document.addEventListener('webkitfullscreenchange', exitHandler);
document.addEventListener('mozfullscreenchange', exitHandler);
document.addEventListener('MSFullscreenChange', exitHandler);

function exitHandler() {
    if (!document.fullscreenElement && !document.webkitFullscreenElement) {
        setFullScreen(false);
    } else {
        setFullScreen(true);
    }
}
  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        >
          <div className="topbar-item">
            <div
              className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
              id="kt_quick_user_toggle"
            >
              <>
                <span className="symbol symbol-35 symbol-light-primary">
                  <span className="symbol-label font-size-h5 font-weight-bold">
                    {/* {user.fullname[0]} */}
                  </span>
                </span>
              </>
            </div>
          </div>
        </OverlayTrigger>
      )}
      <div className="d-none d-md-block">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="quick-user-tooltip">{!FullScreen?"Enter Full Screen":"Exit Full Screen"}</Tooltip>}
        >
          <div className="topbar-item " onClick={() => fullscreen()}>
            <div
              className="btn btn-icon w-auto btn-clear d-flex align-items-center btn-sm mr-3"
            >
              <>
                  {!FullScreen?<i className="fas fa-expand text-primary"></i>:<i className="fas fa-solid fa-compress text-primary"></i>}
                {/* <span className="symbol symbol-35 symbol-light-primary">
                  <span className="symbol-label font-size-h5 font-weight-bold">
  
                  </span>
                </span> */}
              </>
            </div>
          </div>
        </OverlayTrigger>
      </div>
      <MultiCurrency themeData={props.themeData}/>
      <MultiLingual/>
      <Link to="/shortlist" className="mx-2">
      {/* <button type="button" class="btn btn-primary position-relative">
          Inbox
          <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
            99+
            <span class="visually-hidden">unread messages</span>
          </span>
        </button> */}
        <div className="symbol symbol-35 symbol-light-primary symbol-circle position-relative">
          <span class="symbol-badge symbol-badge-top small cart-symbol" >
            {shortListedStone}
          </span>
          <span className="symbol-label font-size-h5 font-weight-bold"><FaShoppingCart /></span>
        </div>
      </Link>  
      {!layoutProps.offcanvas && <UserProfileDropdown language={props.language}/>}  
    </>
  );
}
